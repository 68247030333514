import React from "react"
import PropTypes from "prop-types"
import {Container, Nav, Navbar} from "react-bootstrap";
import logo from '../images/logo.svg';

const Header = () => (
  <header>
    <Navbar collapseOnSelect expand="md" bg="light">
      <Container>
        <Navbar.Brand href="/" className="d-flex align-items-center">
          <img
            src={logo}
            height="26"
            className="mr-1"
            alt="Nextweb software"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link href="/#services">Services</Nav.Link>
            <Nav.Link className="mr-3" href="/#contact">Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
