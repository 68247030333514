import React from 'react'
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "gatsby";
import {FaFacebookF, FaLinkedin, FaTwitter} from "react-icons/fa";

const Footer = () => (
	<footer className="bg-light p-3 p-md-5">
		<Container>
			<Row>
				<Col xs="12" className="mb-4 mb-md-0 text-center">
					<div>© <Link to="/" className="link-secondary">Nextweb Software</Link>. All Rights Reserved</div>
					<Row className="justify-content-center">
						<Col xs="12" md="8"><p className="text-left"><small>Nextweb Software is a consulting and software engineering firm consisting of a group of talented individuals dedicated to their work within the fields of marketing, social media, and big data analytics.</small></p></Col>
					</Row>
					<div className="h4">
						<a href="https://www.linkedin.com/company/nextweb-software" className="mr-2" aria-label="LinkedIn"><FaLinkedin /></a>
						<a href="https://facebook.com/nextwebsoft" className="mr-2" aria-label="Facebook"><FaFacebookF /></a>
						<a href="https://twitter.com/NextwebSoftware" aria-label="Twitter"><FaTwitter /></a>
					</div>
				</Col>
			</Row>
		</Container>
	</footer>
);

export default Footer;